import React, { useState } from 'react';
import Countdown from 'react-countdown';
import quizData from './QuizData';

function Quiz() {

  const [currentQuiz, setCurrentQuiz] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [startGame, setStartGame] = useState(false);
  const [globalScore, setGlobalScore] = useState(0);

  const countdownLevelDelay = 20000;
  const countdownQuestionDelay = 15000;

  const getLevelData = (targetLevel) => {
    return quizData.filter((quiz) => quiz.level === targetLevel);
  };

  const [level, setLevel] = useState(1);
  const [levelData, setLevelData] = useState(getLevelData(1));



  const countdownLevelRenderer = ({ seconds, completed }) => {
    return <span>{seconds} secondes ...</span>;
  };

  const countdownQuestionRenderer = ({ seconds, completed }) => {
    if (seconds < 6) {
      return <span className="text-sm text-red-400 ml-5">{seconds} secondes ...</span>;
    }
    return <span className="text-sm text-grey-400 ml-5">{seconds} secondes ...</span>;
  };

  const handleAnswerChange = (event) => {
    setSelectedAnswer(event.target.value);
  };

  const [countdownQuestionDate, setCountdownQuestionDate] = useState(Date.now() + countdownQuestionDelay);

  const showNextQuestion = () => {
    setSelectedAnswer(null);
    if (currentQuiz < levelData.length - 1) {
      setCurrentQuiz(currentQuiz + 1);
      setCountdownQuestionDate(Date.now() + countdownQuestionDelay)
    } else {
      setShowResult(true);
    }
  };

  const handleSubmit = () => {
    if (selectedAnswer === levelData[currentQuiz].correct) {
      const newScore = score + 1;
      setScore(newScore);
    }
    showNextQuestion();
  };

  const initTheGame = () => {
    setLevel(1);
    setStartGame(true);
    setGlobalScore(0);
    setLevelData(getLevelData(1));
    setCurrentQuiz(0);
    setScore(0);
    setShowResult(false);
  };


  const handleLevelChange = (event) => {
    const maxLevel = Math.max(...quizData.map((quiz) => quiz.level));
    if (level < maxLevel) {
      setLevel(level + 1);
      setLevelData(getLevelData(level + 1));
      setGlobalScore(globalScore + 100 * score);
      setCurrentQuiz(0);
      setScore(0);
      setShowResult(false);
    }
  };

  const CircularButton = ({ children, color, onClick }) => {
    const colorClasses = {
      green: 'bg-green-500 hover:bg-green-600 active:bg-green-700',
      purple: 'bg-purple-400 hover:bg-purple-600 active:bg-purple-700',
      yellow: 'bg-yellow-400 hover:bg-yellow-600 active:bg-yellow-700',
      orange: 'bg-orange-400 hover:bg-orange-600 active:bg-orange-700',
    };

    return (
      <button
        className={`rounded-full my-3 px-4 py-3 text-base font-medium text-white transition duration-200 ${colorClasses[color]}`}
        onClick={onClick}
      >
        {children}
      </button>
    );
  };

  const QuizIntro = () => {
    return (
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Règles du jeu</h2>
        <p className="text-sm text-gray-600">Vous devez répondre au maximum de questions pour remporter la partie.</p>
        <p className="text-sm text-gray-600">Répondez correctement à au moins 80% des réponses de chaque niveau pour passer au niveau suivant.</p>
        <CircularButton color="green" onClick={() => initTheGame()}>
          <span className="uppercase">Commencer le jeu</span>
        </CircularButton>
      </div>
    );
  };

  const QuizResult = () => {
    return (
      <div className="text-center">
        <h2 className="text-2xl font-bold mb-4">Résultat</h2>
        <p className="text-lg">Vous avez répondu correctement à {(score / levelData.length * 100).toFixed()}% questions du niveau {level}.</p>

        {score >= 4 ? (
          <>
            <p className="text-3xl my-4">🎉</p>
            <p className="text-md bg-green-400">
              <span>Bravo vous passez au niveau suivant ! </span>
              {/* <Countdown onComplete={handleLevelChange} date={Date.now() + countdownLevelDelay} renderer={countdownLevelRenderer} /> */}
            </p>
            <CircularButton color="green" onClick={() => handleLevelChange()}>
              <span className="uppercase">Continuer</span>
            </CircularButton>
          </>
        ) :
          (<>
            <p className="text-3xl my-4">🥺</p>
            <p className="text-md bg-red-400">Vous avez échoué, l'aventure s'arrête là.</p>
            <CircularButton color="green" onClick={() => initTheGame()}>
              <span className="uppercase">Recommencer</span>
            </CircularButton>
          </>
          )
        }

      </div>
    );
  };

  const QuizQuestion = () => {
    return (
      <div>
        <h2 className="text-xl font-semibold mb-4">{levelData[currentQuiz].question}</h2>
        <div className="mb-4">
          {levelData[currentQuiz].options.map((option, index) => (
            <label key={index} className="block mb-2">
              <input
                type="radio"
                name="answer"
                value={option.value}
                checked={selectedAnswer === option.value}
                onChange={handleAnswerChange}
                className="mr-2"
              />
              {option.label}
            </label>
          ))}
        </div>
        <button
          onClick={handleSubmit}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          Soumettre
        </button>
        <Countdown key={countdownQuestionDate} date={countdownQuestionDate} renderer={countdownQuestionRenderer} onComplete={() => handleSubmit()} />
      </div>
    );
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="absolute top-0 right-0 m-3">
        <h3 className="text-white text-xl text-center uppercase">Level {level}</h3>
        <p className="text-white uppercase"><span className="text-green-300">{globalScore}</span> pts</p>
      </div>
      <div className="w-full sm:w-5/6 md:w-5/6 max-w-screen-md">
        <h1 className="text-5xl text-white font-bold mb-10 text-center">Africa Quizz</h1>
        <div className="bg-white p-8 rounded-none sm:rounded shadow-md w-full">
          {startGame === false ? (
            <QuizIntro />
          ) :
            (<>
              {showResult ? (
                <QuizResult />
              ) : (
                <QuizQuestion />
              )}
            </>)}
        </div>
      </div>
    </div>
  );

}

export default Quiz;
