const quizData = [
    // Level 1
    {
        question: "Quel est le pays le plus peuplé d'Afrique ?",
        options: [
            { label: "Égypte", value: "a" },
            { label: "Nigeria", value: "b" },
            { label: "Afrique du Sud", value: "c" },
            { label: "Kenya", value: "d" }
        ],
        correct: "b",
        level: 1
    },
    {
        question: "Quel est le plus long fleuve d'Afrique ?",
        options: [
            { label: "Niger", value: "a" },
            { label: "Congo", value: "b" },
            { label: "Nil", value: "c" },
            { label: "Zambèze", value: "d" }
        ],
        correct: "c",
        level: 1
    },
    {
        question: "Quel est le désert le plus vaste d'Afrique ?",
        options: [
            { label: "Kalahari", value: "a" },
            { label: "Namib", value: "b" },
            { label: "Sahara", value: "c" },
            { label: "Libyque", value: "d" }
        ],
        correct: "c",
        level: 1
    },
    {
        question: "Quelle est la capitale du Ghana ?",
        options: [
            { label: "Accra", value: "a" },
            { label: "Lagos", value: "b" },
            { label: "Abuja", value: "c" },
            { label: "Kampala", value: "d" }
        ],
        correct: "a",
        level: 1
    },
    {
        question: "Quel pays africain est connu pour ses pyramides ?",
        options: [
            { label: "Afrique du Sud", value: "a" },
            { label: "Égypte", value: "b" },
            { label: "Maroc", value: "c" },
            { label: "Algérie", value: "d" }
        ],
        correct: "b",
        level: 1
    },
    // Level 2
    {
        question: "Quel pays est surnommé 'l'île rouge' ?",
        options: [
            { label: "Madagascar", value: "a" },
            { label: "Maurice", value: "b" },
            { label: "Seychelles", value: "c" },
            { label: "Comores", value: "d" }
        ],
        correct: "a",
        level: 2
    },
    {
        question: "Quelle est la capitale de l'Éthiopie ?",
        options: [
            { label: "Nairobi", value: "a" },
            { label: "Addis-Abeba", value: "b" },
            { label: "Kigali", value: "c" },
            { label: "Dar es Salaam", value: "d" }
        ],
        correct: "b",
        level: 2
    },
    {
        question: "Quel est le plus grand lac d'Afrique par superficie ?",
        options: [
            { label: "Lac Tanganyika", value: "a" },
            { label: "Lac Malawi", value: "b" },
            { label: "Lac Victoria", value: "c" },
            { label: "Lac Tchad", value: "d" }
        ],
        correct: "c",
        level: 2
    },
    {
        question: "Quel pays est connu pour ses montagnes Atlas ?",
        options: [
            { label: "Égypte", value: "a" },
            { label: "Nigeria", value: "b" },
            { label: "Afrique du Sud", value: "c" },
            { label: "Maroc", value: "d" }
        ],
        correct: "d",
        level: 2
    },
    {
        question: "Quel est le pays le plus vaste d'Afrique ?",
        options: [
            { label: "Algérie", value: "a" },
            { label: "Soudan", value: "b" },
            { label: "Libye", value: "c" },
            { label: "Tchad", value: "d" }
        ],
        correct: "a",
        level: 2
    },
    // Level 3
    {
        question: "Quelle ville est surnommée la 'Perle de l'Afrique' ?",
        options: [
            { label: "Kampala", value: "a" },
            { label: "Lagos", value: "b" },
            { label: "Abidjan", value: "c" },
            { label: "Johannesburg", value: "d" }
        ],
        correct: "a",
        level: 3
    },
    {
        question: "Quel pays africain est célèbre pour ses réserves de diamants ?",
        options: [
            { label: "Botswana", value: "a" },
            { label: "Ghana", value: "b" },
            { label: "Zimbabwe", value: "c" },
            { label: "Namibie", value: "d" }
        ],
        correct: "a",
        level: 3
    },
    {
        question: "Quel pays africain est enclavé ?",
        options: [
            { label: "Kenya", value: "a" },
            { label: "Éthiopie", value: "b" },
            { label: "Ouganda", value: "c" },
            { label: "Somalie", value: "d" }
        ],
        correct: "c",
        level: 3
    },
    {
        question: "Quelle est la langue officielle du Mozambique ?",
        options: [
            { label: "Anglais", value: "a" },
            { label: "Français", value: "b" },
            { label: "Portugais", value: "c" },
            { label: "Espagnol", value: "d" }
        ],
        correct: "c",
        level: 3
    },
    {
        question: "Quel est le principal produit d'exportation du Nigéria ?",
        options: [
            { label: "Cacao", value: "a" },
            { label: "Pétrole", value: "b" },
            { label: "Café", value: "c" },
            { label: "Or", value: "d" }
        ],
        correct: "b",
        level: 3
    },
    // Level 4
    {
        question: "Quelle est la monnaie officielle de l'Afrique du Sud ?",
        options: [
            { label: "Rand", value: "a" },
            { label: "Shilling", value: "b" },
            { label: "Naira", value: "c" },
            { label: "Dollar", value: "d" }
        ],
        correct: "a",
        level: 4
    },
    {
        question: "Quel pays africain est connu pour la danse traditionnelle 'Gwara Gwara' ?",
        options: [
            { label: "Ghana", value: "a" },
            { label: "Afrique du Sud", value: "b" },
            { label: "Côte d'Ivoire", value: "c" },
            { label: "Mali", value: "d" }
        ],
        correct: "b",
        level: 4
    },
    {
        question: "Quel est le plat national du Sénégal ?",
        options: [
            { label: "Jollof Rice", value: "a" },
            { label: "Fufu", value: "b" },
            { label: "Couscous", value: "c" },
            { label: "Thieboudienne", value: "d" }
        ],
        correct: "d",
        level: 4
    },
    {
        question: "Quelle est la plus grande ville d'Afrique ?",
        options: [
            { label: "Lagos", value: "a" },
            { label: "Le Caire", value: "b" },
            { label: "Kinshasa", value: "c" },
            { label: "Johannesburg", value: "d" }
        ],
        correct: "a",
        level: 4
    },
    {
        question: "Quel pays africain a organisé la Coupe du Monde de la FIFA 2010 ?",
        options: [
            { label: "Nigeria", value: "a" },
            { label: "Ghana", value: "b" },
            { label: "Cameroun", value: "c" },
            { label: "Afrique du Sud", value: "d" }
        ],
        correct: "d",
        level: 4
    },
    // Level 5
    {
        question: "Quel est le plus grand pays d'Afrique en termes de superficie ?",
        options: [
            { label: "Soudan", value: "a" },
            { label: "Algérie", value: "b" },
            { label: "Libye", value: "c" },
            { label: "Tchad", value: "d" }
        ],
        correct: "b",
        level: 5
    },
    {
        question: "Quel est le principal produit d'exportation de la Côte d'Ivoire ?",
        options: [
            { label: "Pétrole", value: "a" },
            { label: "Cacao", value: "b" },
            { label: "Café", value: "c" },
            { label: "Or", value: "d" }
        ],
        correct: "b",
        level: 5
    },
    {
        question: "Quel pays africain est connu pour ses pyramides de Gizeh ?",
        options: [
            { label: "Soudan", value: "a" },
            { label: "Libye", value: "b" },
            { label: "Égypte", value: "c" },
            { label: "Algérie", value: "d" }
        ],
        correct: "c",
        level: 5
    },
    {
        question: "Quel est le plus grand désert du monde ?",
        options: [
            { label: "Sahara", value: "a" },
            { label: "Gobi", value: "b" },
            { label: "Atacama", value: "c" },
            { label: "Kalahari", value: "d" }
        ],
        correct: "a",
        level: 5
    },
    {
        question: "Quel est le pays d'origine de Nelson Mandela ?",
        options: [
            { label: "Nigéria", value: "a" },
            { label: "Éthiopie", value: "b" },
            { label: "Afrique du Sud", value: "c" },
            { label: "Ghana", value: "d" }
        ],
        correct: "c",
        level: 5
    },
    // Level 6
    {
        question: "Quel pays est connu pour le parc national de Serengeti ?",
        options: [
            { label: "Kenya", value: "a" },
            { label: "Tanzanie", value: "b" },
            { label: "Ouganda", value: "c" },
            { label: "Rwanda", value: "d" }
        ],
        correct: "b",
        level: 6
    },
    {
        question: "Quelle est la principale exportation du Ghana ?",
        options: [
            { label: "Pétrole", value: "a" },
            { label: "Cacao", value: "b" },
            { label: "Café", value: "c" },
            { label: "Or", value: "d" }
        ],
        correct: "b",
        level: 6
    },
    {
        question: "Quel pays africain a été le premier à obtenir son indépendance ?",
        options: [
            { label: "Ghana", value: "a" },
            { label: "Nigeria", value: "b" },
            { label: "Kenya", value: "c" },
            { label: "Sénégal", value: "d" }
        ],
        correct: "a",
        level: 6
    },
    {
        question: "Quelle est la capitale de l'Afrique du Sud ?",
        options: [
            { label: "Johannesburg", value: "a" },
            { label: "Pretoria", value: "b" },
            { label: "Le Cap", value: "c" },
            { label: "Bloemfontein", value: "d" }
        ],
        correct: "b",
        level: 6
    },
    {
        question: "Quel est le plus grand lac d'Afrique ?",
        options: [
            { label: "Lac Tanganyika", value: "a" },
            { label: "Lac Victoria", value: "b" },
            { label: "Lac Malawi", value: "c" },
            { label: "Lac Tchad", value: "d" }
        ],
        correct: "b",
        level: 6
    },
    // Level 7
    {
        question: "Quelle est la capitale du Kenya ?",
        options: [
            { label: "Kampala", value: "a" },
            { label: "Dodoma", value: "b" },
            { label: "Nairobi", value: "c" },
            { label: "Addis-Abeba", value: "d" }
        ],
        correct: "c",
        level: 7
    },
    {
        question: "Quel pays africain est connu pour sa production de café ?",
        options: [
            { label: "Éthiopie", value: "a" },
            { label: "Kenya", value: "b" },
            { label: "Ouganda", value: "c" },
            { label: "Rwanda", value: "d" }
        ],
        correct: "a",
        level: 7
    },
    {
        question: "Quel pays africain a le plus grand nombre d'éléphants ?",
        options: [
            { label: "Afrique du Sud", value: "a" },
            { label: "Botswana", value: "b" },
            { label: "Kenya", value: "c" },
            { label: "Tanzanie", value: "d" }
        ],
        correct: "b",
        level: 7
    },
    {
        question: "Quelle est la langue officielle de la Côte d'Ivoire ?",
        options: [
            { label: "Anglais", value: "a" },
            { label: "Français", value: "b" },
            { label: "Espagnol", value: "c" },
            { label: "Portugais", value: "d" }
        ],
        correct: "b",
        level: 7
    },
    {
        question: "Quel pays africain est le plus grand producteur de cacao ?",
        options: [
            { label: "Nigéria", value: "a" },
            { label: "Ghana", value: "b" },
            { label: "Côte d'Ivoire", value: "c" },
            { label: "Cameroun", value: "d" }
        ],
        correct: "c",
        level: 7
    },
    // Level 8
    {
        question: "Quel pays africain est célèbre pour le parc national du Kilimandjaro ?",
        options: [
            { label: "Kenya", value: "a" },
            { label: "Tanzanie", value: "b" },
            { label: "Ouganda", value: "c" },
            { label: "Rwanda", value: "d" }
        ],
        correct: "b",
        level: 8
    },
    {
        question: "Quelle est la capitale du Sénégal ?",
        options: [
            { label: "Bamako", value: "a" },
            { label: "Ouagadougou", value: "b" },
            { label: "Dakar", value: "c" },
            { label: "Niamey", value: "d" }
        ],
        correct: "c",
        level: 8
    },
    {
        question: "Quel est le nom de la monnaie officielle de l'Éthiopie ?",
        options: [
            { label: "Shilling", value: "a" },
            { label: "Birr", value: "b" },
            { label: "Cedi", value: "c" },
            { label: "Franc", value: "d" }
        ],
        correct: "b",
        level: 8
    },
    {
        question: "Quel pays africain est connu pour ses safaris ?",
        options: [
            { label: "Égypte", value: "a" },
            { label: "Kenya", value: "b" },
            { label: "Libye", value: "c" },
            { label: "Soudan", value: "d" }
        ],
        correct: "b",
        level: 8
    },
    {
        question: "Quel est le plus haut sommet d'Afrique ?",
        options: [
            { label: "Mont Elgon", value: "a" },
            { label: "Mont Kenya", value: "b" },
            { label: "Mont Kilimandjaro", value: "c" },
            { label: "Mont Rwenzori", value: "d" }
        ],
        correct: "c",
        level: 8
    },
    // Level 9
    {
        question: "Quel est le fleuve principal du Nigéria ?",
        options: [
            { label: "Nil", value: "a" },
            { label: "Congo", value: "b" },
            { label: "Niger", value: "c" },
            { label: "Zambèze", value: "d" }
        ],
        correct: "c",
        level: 9
    },
    {
        question: "Quelle est la capitale de la Zambie ?",
        options: [
            { label: "Harare", value: "a" },
            { label: "Lusaka", value: "b" },
            { label: "Windhoek", value: "c" },
            { label: "Lilongwe", value: "d" }
        ],
        correct: "b",
        level: 9
    },
    {
        question: "Quel pays africain est connu pour le vin ?",
        options: [
            { label: "Égypte", value: "a" },
            { label: "Afrique du Sud", value: "b" },
            { label: "Maroc", value: "c" },
            { label: "Tunisie", value: "d" }
        ],
        correct: "b",
        level: 9
    },
    {
        question: "Quel est le principal produit d'exportation de l'Algérie ?",
        options: [
            { label: "Café", value: "a" },
            { label: "Pétrole", value: "b" },
            { label: "Cacao", value: "c" },
            { label: "Thé", value: "d" }
        ],
        correct: "b",
        level: 9
    },
    {
        question: "Quelle est la monnaie officielle du Kenya ?",
        options: [
            { label: "Dollar", value: "a" },
            { label: "Rand", value: "b" },
            { label: "Shilling", value: "c" },
            { label: "Franc", value: "d" }
        ],
        correct: "c",
        level: 9
    },
    // Level 10
    {
        question: "Quel pays africain est le plus grand producteur d'or ?",
        options: [
            { label: "Afrique du Sud", value: "a" },
            { label: "Ghana", value: "b" },
            { label: "Mali", value: "c" },
            { label: "Tanzanie", value: "d" }
        ],
        correct: "a",
        level: 10
    },
    {
        question: "Quel est le plus grand parc national d'Afrique ?",
        options: [
            { label: "Parc national Kruger", value: "a" },
            { label: "Parc national du Serengeti", value: "b" },
            { label: "Parc national de la Garamba", value: "c" },
            { label: "Parc national du Kilimandjaro", value: "d" }
        ],
        correct: "a",
        level: 10
    },
    {
        question: "Quelle est la capitale de l'Angola ?",
        options: [
            { label: "Lagos", value: "a" },
            { label: "Luanda", value: "b" },
            { label: "Accra", value: "c" },
            { label: "Abuja", value: "d" }
        ],
        correct: "b",
        level: 10
    },
    {
        question: "Quel pays africain est connu pour ses réserves naturelles ?",
        options: [
            { label: "Kenya", value: "a" },
            { label: "Afrique du Sud", value: "b" },
            { label: "Tanzanie", value: "c" },
            { label: "Tous les précédents", value: "d" }
        ],
        correct: "d",
        level: 10
    },
    {
        question: "Quelle est la plus grande rivière d'Afrique de l'Ouest ?",
        options: [
            { label: "Nil", value: "a" },
            { label: "Congo", value: "b" },
            { label: "Niger", value: "c" },
            { label: "Zambèze", value: "d" }
        ],
        correct: "c",
        level: 10
    },
];

export default quizData;
